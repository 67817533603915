import { Component, OnInit } from '@angular/core';
import { SeAnalyticsService } from 'se-analytics';
import { Discount } from '../models/discount.model';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.scss']
})
export class AddDiscountComponent implements OnInit {
  public discount: Discount;

  constructor(public seAnalyticsService: SeAnalyticsService) { }

  ngOnInit(): void {
    this.discount = new Discount({ discounted_item_variation_ids: [], required_item_variation_ids: [] });
    this.seAnalyticsService.trackEvent('pageView' , {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'AddDiscount'
    });
  }
}
