import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable, debounceTime, take } from 'rxjs';
import { SeAnalyticsService } from 'se-analytics';
import { SeFeFormFieldConfig } from 'se-fe-form-field';
import { SeFeScrollService, SeFeScrollToAlignment } from 'se-fe-scroll';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { environment } from '../../../environments/environment';
import { ItemVariationService } from '../../sale-items/services/item-variation.service';
import { Discount } from '../models/discount.model';
import { DiscountFormConfigService } from '../services/discount-form-config.service';
import { DiscountFormService } from '../services/discount-form.service';
import { DiscountSummaryService } from '../services/discount-summary.service';
import { DiscountService } from '../services/discount.service';
import { loadDiscount } from './state/edit-discounts.actions';
import { selectDiscount } from './state/edit-discounts.selectors';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';

@Component({
  selector: 'app-edit-discounts-v2',
  templateUrl: './edit-discounts-v2.component.html',
  styleUrls: ['./edit-discounts-v2.component.scss'],
  providers: [DiscountFormConfigService],
})
export class EditDiscountsV2Component implements OnInit {
  @Input() public discount: Discount;
  @Input() public saving: boolean;
  public hasSubmissionErrors = false;
  public submissionErrors: string[];
  availableCodeCount: number;
  codeMaxUsage: string = '';
  discount$: Observable<Discount>;
  discountID: number;
  discountStyle: string = '';
  editDiscountForm: FormGroup;
  initialCodeCount: number;
  suppliedCodeName: string = '';
  usedCodeCount: number;
  isDiscountScopeSubtotal: boolean = false;

  // What type of discount do you want to create?
  uneditableDiscounts = {
    Automatic: 'Automatic Discount',
    Individual: 'Individual Discount Codes',
    Code: 'Reusable Discount Codes',
  };
  // What do you want to name this discount?
  editDiscountNameConfig: SeFeFormFieldConfig;
  // What is the value of the discount?
  discountValueAmountConfig: SeFeFormFieldConfig;
  discountValueTypeOptionsConfig: SeFeFormFieldConfig;
  discountValueTypeOptions: any;
  discountValueMaxConfig: SeFeFormFieldConfig;
  discountValueMaxButton = false;
  showDiscountValueMaxField = false;
  // How do you want to apply the discount?
  applyDiscountTypeOptionsConfig: SeFeFormFieldConfig;
  applyDiscountTypeOptions: any;
  applyDiscountSaleItemButton = false;
  applyDiscountItemOptionsConfig: SeFeFormFieldConfig;
  applyDiscountItemOptions: any;
  applyDiscountSetAmountField = false;
  applyDiscountSetAmountConfig: SeFeFormFieldConfig;
  // What are the requirements to use this discount?
  discountUseRequirementsOptionsConfig: SeFeFormFieldConfig;
  discountUseRequirementsOptions: any;
  minimumSpendAmountFieldVisible = false;
  minItemsFieldVisible = false;
  minimumSpendAmountFieldConfig: SeFeFormFieldConfig;
  minimumItemsQtyFieldConfig: SeFeFormFieldConfig;

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: number,
    private fb: FormBuilder,
    private discountFormService: DiscountFormService,
    private itemVariationService: ItemVariationService,
    private discountService: DiscountService,
    private seFeScrollService: SeFeScrollService,
    private toastService: SeFeToastService,
    private router: Router,
    private store: Store,
    private discountFormConfig: DiscountFormConfigService,
    private discountSummaryService: DiscountSummaryService,
    public seAnalyticsService: SeAnalyticsService
  ) {
    this.initializeFormConfig();
    this.store.dispatch(loadDiscount());
    this.discount$ = this.store.select(selectDiscount);
  }

  ngOnInit(): void {
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'EditDiscount',
    });
    this.createForm();
    this.discountValueTypeChange();
    this.handleApplyDiscountTypeChange();
    this.handleDiscountUseRequirementsChange();
    this.subscribeToFormChanges();

    const discountValueAmountControl = this.editDiscountForm.get('discountValueAmount');
    const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

    discountValueMaxOffControl.valueChanges.subscribe((maxValue) => {
      this.discountFormService.updateDiscountValidators(discountValueAmountControl, maxValue);
    });

    discountValueAmountControl.valueChanges.subscribe((maxValue) => {
      this.discountFormService.updateDiscountValidators(discountValueAmountControl, discountValueMaxOffControl.value);
    });

    this.discount$.subscribe((discount) => {
      if (discount) {
        this.bindFormControls(discount);
        this.discountID = discount.id;
        this.discountStyle = discount.discount_style;
        this.codeMaxUsage = discount.code_max_usage;
        this.usedCodeCount = discount.used_code_count;
        this.availableCodeCount = discount.available_count;
        this.initialCodeCount = discount.code_count;
        this.suppliedCodeName = discount.supplied_code;
        this.isDiscountScopeSubtotal = discount.discount_scope === 'Order';
      }
    });

    this.setupConditionalValidation();
  }

  private initializeFormConfig(): void {
    this.editDiscountNameConfig = this.discountFormConfig.editDiscountNameConfig;
    this.discountValueAmountConfig = this.discountFormConfig.discountValueAmountConfig;
    this.discountValueTypeOptionsConfig = this.discountFormConfig.discountValueTypeOptionsConfig;
    this.discountValueTypeOptions = this.discountFormConfig.discountValueTypeOptions;
    this.discountValueMaxConfig = this.discountFormConfig.discountValueMaxConfig;
    this.applyDiscountTypeOptionsConfig = this.discountFormConfig.applyDiscountTypeOptionsConfig;
    this.applyDiscountTypeOptions = this.discountFormConfig.applyDiscountTypeOptions;
    this.discountUseRequirementsOptionsConfig = this.discountFormConfig.discountUseRequirementsOptionsConfig;
    this.discountUseRequirementsOptions = this.discountFormConfig.discountUseRequirementsOptions;
    this.applyDiscountItemOptionsConfig = this.discountFormConfig.applyDiscountItemOptionsConfig;
    this.applyDiscountItemOptions = this.discountFormConfig.applyDiscountItemOptions;
    this.applyDiscountSetAmountConfig = this.discountFormConfig.applyDiscountSetAmountConfig;
    this.minimumSpendAmountFieldConfig = this.discountFormConfig.minimumSpendAmountFieldConfig;
    this.minimumItemsQtyFieldConfig = this.discountFormConfig.minimumItemsQtyFieldConfig;
  }

  getItemVariationService(): ItemVariationService {
    return this.itemVariationService;
  }

  createForm(): void {
    this.editDiscountForm = this.fb.group(
      {
        // What do you want to name this discount?
        discountName: ['', [Validators.required, Validators.maxLength(64), Validators.minLength(2)]],
        // What is the value of the discount?
        discountValueAmount: [
          '',
          [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'), Validators.min(1), Validators.max(100)],
        ],
        discountValueType: ['', [Validators.required]],
        discountValueMaxOff: [''],

        // How do you want to apply the discount?
        applyDiscountTypeRadios: ['', [Validators.required]],
        saleItemsPerOrderRadios: [''],
        saleItemsPerOrderSelections: [''],
        setAmountQtyField: [''],

        // What are the requirements to use this discount?
        discountUseRequirementsRadios: ['', [Validators.required]],
        minimumSpendAmountField: [''],
        saleItemsRequiredSelections: [''],
        minimumItemsQtyField: [''],

        // When will this discount be available?
        fromDate: [this.getTodaysDate(), Validators.required],
        toDate: [''],
      },
      {
        validators: this.discountFormConfig.dateRangeValidator('fromDate', 'toDate'),
      }
    );

    this.discountValueTypeChange();
  }

  get formControls() {
    return this.editDiscountForm.controls;
  }

  /*
   * Check nested validations
   * */
  checkFormValidity(): void {
    if (!environment.production) {
      console.log('form valid: ', this.editDiscountForm.valid); // false if the form is invalid
    }
    for (const key of Object.keys(this.editDiscountForm.controls)) {
      const controlErrors = this.editDiscountForm.get(key)?.errors;
      if (controlErrors != null && !environment.production) {
        console.log(`Errors in ${key}:`, controlErrors);
      } else {
        const nestedGroup = this.editDiscountForm.get(key);
        if (nestedGroup instanceof FormGroup) {
          for (const nestedKey of Object.keys(nestedGroup.controls)) {
            const nestedControlErrors = nestedGroup.get(nestedKey)?.errors;
            if (nestedControlErrors != null && !environment.production) {
              console.log(`Errors in ${nestedKey}:`, nestedControlErrors);
            }
          }
        }
      }
    }
  }

  async editDiscountSubmit(): Promise<void> {
    this.checkFormValidity();
    if (this.editDiscountForm.valid) {
      try {
        this.saving = true;
        this.hasSubmissionErrors = false;
        this.submissionErrors = [];

        const discount = this.mapForm();
        const response = await this.discountService.save(discount);

        if (response.id) {
          const successMessage = 'Successfully Edited';
          this.toastService.addToast({
            type: SeFeToastTypes.Success,
            message: successMessage,
          });
          const goTo = `/discounts/${response.id}/detail`;
          await this.router.navigate([goTo]);
        }
      } catch (e) {
        console.error('Error saving discount', e);
        this.saving = false;
        this.hasSubmissionErrors = true;
        if (e.error && e.error.errors) {
          this.submissionErrors = Object.values(e.error.errors).flat() as string[];
        } else {
          this.submissionErrors = ['Submission Error'];
        }
        const submissionErrorsElement = document.getElementById('submission_errors');
        if (submissionErrorsElement) {
          this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, submissionErrorsElement, {
            offset: 115,
          });
        }
        this.toastService.addToast({
          type: SeFeToastTypes.Error,
          message: 'Error Saving',
        });
      } finally {
        this.saving = false;
      }
    } else {
      this.hasSubmissionErrors = true;
      if (this.editDiscountForm.errors?.dateRangeInvalid) {
        this.submissionErrors = this.discountFormConfig.getDateRangeErrorMessage(
          this.editDiscountForm,
          'fromDate',
          'toDate'
        );
      } else {
        this.submissionErrors = this.discountFormConfig.getFormErrors(this.editDiscountForm);
      }
      this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, document.getElementById('submission_errors'), {
        offset: 115,
      });
    }
  }

  /**
   * Handles the change event of the discount value type.
   * Updates the UI based on the selected value.
   */
  discountValueTypeChange(): void {
    this.editDiscountForm.get('discountValueType').valueChanges.subscribe((value) => {
      const discountValueAmountControl = this.editDiscountForm.get('discountValueAmount');
      const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

      if (value === 'percentage') {
        this.discountValueMaxButton = true; // Show the "Maximum $ dollars off" button
        discountValueAmountControl.setValidators([
          Validators.required,
          Validators.pattern('^(100|\\d{1,2})(\\.\\d{1,2})?$'),
          Validators.min(1),
          Validators.max(100),
          this.discountFormService.percentageDiscountValidator(),
        ]);
      } else if (value === 'amount') {
        this.discountValueMaxButton = false; // Hide the "Maximum $ dollars off" button
        this.showDiscountValueMaxField = false;
        discountValueMaxOffControl.clearValidators();
        discountValueMaxOffControl.setValue('');
        discountValueAmountControl.clearValidators();
        discountValueAmountControl.setValidators([
          Validators.required,
          Validators.pattern('^\\d+(\\.\\d{1,2})?$'),
          Validators.min(1),
          Validators.max(999_999_999.99),
        ]);
      }

      discountValueAmountControl.updateValueAndValidity();
      discountValueMaxOffControl.updateValueAndValidity();
    });
  }

  /**
   * Toggles the discountValueMaxField property and clears the discountValueMaxOff form control if the field is toggled off.
   */
  discountValueMaxToggle(): void {
    const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

    this.showDiscountValueMaxField = !this.showDiscountValueMaxField;

    if (this.showDiscountValueMaxField) {
      discountValueMaxOffControl.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(1),
      ]);
      discountValueMaxOffControl.markAsPristine();
      discountValueMaxOffControl.markAsUntouched();
    } else {
      discountValueMaxOffControl.clearValidators();
      discountValueMaxOffControl.setValue(''); // Clear the value if not required
    }

    discountValueMaxOffControl.updateValueAndValidity();
  }

  /**
   * Handles the change event of the applyDiscountTypeRadios form control.
   * Subscribes to the valueChanges event and updates the component's state accordingly.
   * If the selected value is not 'saleItems', it resets the saleItemsPerOrderRadios and applyDiscountSetAmountField.
   * If the selected value is not 'setAmount', it resets the setAmountQtyField.
   */
  handleApplyDiscountTypeChange(): void {
    this.editDiscountForm.get('applyDiscountTypeRadios').valueChanges.subscribe((value) => {
      this.applyDiscountSaleItemButton = value === 'saleItems';
      if (value !== 'saleItems') {
        this.editDiscountForm.get('saleItemsPerOrderRadios').setValue('');
        this.applyDiscountSetAmountField = false;
      }
    });

    this.editDiscountForm.get('saleItemsPerOrderRadios').valueChanges.subscribe((value) => {
      this.applyDiscountSetAmountField = value === 'setAmount';
      if (value !== 'setAmount') {
        this.editDiscountForm.get('setAmountQtyField').setValue('');
      }
    });
  }

  /**
   * Handles the change event of the discount use requirements radios.
   * Updates the visibility of the minimum spend amount field and the minimum items quantity field based on the selected value.
   * If the minimum spend amount field is not visible, it sets its value to an empty string.
   * If the minimum items quantity field is not visible, it sets its value to an empty string.
   */
  handleDiscountUseRequirementsChange(): void {
    this.editDiscountForm.get('discountUseRequirementsRadios').valueChanges.subscribe((value) => {
      this.minimumSpendAmountFieldVisible = value === 'minimumSpend';
      this.minItemsFieldVisible = value === 'minimumItems';

      if (!this.minimumSpendAmountFieldVisible) {
        this.editDiscountForm.get('minimumSpendAmountField').setValue('');
      }
      if (!this.minItemsFieldVisible) {
        this.editDiscountForm.get('minimumItemsQtyField').setValue('');
      }
    });
  }

  private getTodaysDate(): string {
    return DateTime.now().toISODate();
  }

  excludeDatesAfterEnd(): { end: string }[] {
    const end_date = DateTime.now();
    return [{ end: end_date.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT) }];
  }

  excludeDatesBeforeStart(): { end: string }[] {
    if (!this.editDiscountForm.value.fromDate) {
      return [];
    }
    const startDate = DateTime.fromFormat(
      this.editDiscountForm.value.fromDate,
      this.discountFormService.UI_DATE_FORMAT
    );
    return [
      {
        end: startDate.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT),
      },
    ];
  }

  bindFormControls(discount: Discount): void {
    this.editDiscountForm.get('discountName').setValue(discount.name);
    this.editDiscountForm.get('discountValueAmount').setValue(discount.discount_value);
    this.editDiscountForm.get('discountValueType').setValue(discount.discount_type);
    this.editDiscountForm.get('discountValueMaxOff').setValue(discount.maximum_discount_value);
    this.showDiscountValueMaxField = !!discount.maximum_discount_value;

    if (discount.starts_at) {
      const fromDate = DateTime.fromISO(discount.starts_at).toFormat(this.discountFormService.UI_DATE_FORMAT);
      this.editDiscountForm.get('fromDate').setValue(fromDate);
    }
    if (discount.expires_at) {
      const toDate = DateTime.fromISO(discount.expires_at).toFormat(this.discountFormService.UI_DATE_FORMAT);
      this.editDiscountForm.get('toDate').setValue(toDate);
    }

    this.editDiscountForm
      .get('applyDiscountTypeRadios')
      .setValue(discount.discount_scope === 'Order' ? 'subtotal' : 'saleItems'); // 'Item' or 'Order'?

    this.editDiscountForm.get('saleItemsPerOrderSelections').setValue(discount.discounted_item_variation_ids);

    this.editDiscountForm
      .get('saleItemsPerOrderRadios')
      .setValue(this.getSaleItemsPerOrderValue(discount.max_eligible_items));
    this.editDiscountForm.get('setAmountQtyField').setValue(discount.max_eligible_items || null);

    this.editDiscountForm
      .get('discountUseRequirementsRadios')
      .setValue(
        discount.requirement_type === 'None' || discount.requirement_type === null
          ? 'noRequirements'
          : discount.requirement_type === 'Quantity'
          ? 'minimumItems'
          : 'minimumSpend'
      );

    this.editDiscountForm.get('saleItemsRequiredSelections').setValue(discount.required_item_variation_ids);
    this.editDiscountForm.get('minimumItemsQtyField').setValue(discount.required_items_quantity);
    this.editDiscountForm.get('minimumSpendAmountField').setValue(discount.minimum_sale_total_cents / 100);
  }

  getSaleItemsPerOrderValue(maxEligibleItems: number | null): string | null {
    if (maxEligibleItems === null) {
      return null;
    }
    switch (maxEligibleItems) {
      case 0:
        return 'allItems';
      case 1:
        return 'oneItem';
      default:
        return 'setAmount';
    }
  }

  /**
   * Updates the exclude dates.
   */
  public updateExcludeDates(): void {}

  /**
   * Updates the discounted items in the form.
   */
  updateDiscountedItemsTable(selectedItems: number[]): void {
    if (!Array.isArray(selectedItems)) {
      selectedItems = selectedItems ? [selectedItems] : [];
    }
    const discountedItems = selectedItems.filter((item) => item).map((item) => item);
    this.editDiscountForm.get('saleItemsPerOrderSelections')?.setValue(discountedItems);
    const applyDiscountType = this.editDiscountForm.get('applyDiscountTypeRadios')?.value;
    if (applyDiscountType === 'saleItems' && discountedItems.length === 0) {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors({ required: true });
    } else {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors(null);
    }
    const discountedItemsStrings = discountedItems.map(String);
    this.fetchItemVariations(discountedItemsStrings);
    this.validateSelectedItems();
  }

  private fetchItemVariations(discountedItems: string[]): void {
    if (discountedItems.length > 0) {
      const queryParams = {
        id_list: discountedItems.join(','),
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        if (!environment.production) {
          console.log('Fetch sale items:', response.result);
        }
      });
    }
  }

  private validateSelectedItems(): void {
    const applyDiscountType = this.editDiscountForm.get('applyDiscountTypeRadios')?.value;
    const discountedItems = this.editDiscountForm.get('saleItemsPerOrderSelections')?.value || [];

    if (applyDiscountType === 'saleItems' && discountedItems.length === 0) {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors({
        required: true,
      });
    } else {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors(null);
    }
  }

  /**
   * Updates the required items in the form.
   */
  updateRequiredItemsTable(requiredItems: number[]): void {
    if (!Array.isArray(requiredItems)) {
      requiredItems = requiredItems ? [requiredItems] : [];
    }
    const requiredItemIds = requiredItems.filter((item) => item).map((item) => item);
    this.editDiscountForm.get('saleItemsRequiredSelections')?.setValue(requiredItemIds);
    const discountRequirements = this.editDiscountForm.get('discountUseRequirementsRadios')?.value;
    if (discountRequirements === 'minimumItems' && requiredItemIds.length === 0) {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors({ required: true });
    } else {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors(null);
    }
    const requiredItemIdsStrings = requiredItemIds.map(String);
    this.fetchRequiredItemVariations(requiredItemIdsStrings);
    this.validateRequiredItems();
  }

  private fetchRequiredItemVariations(requiredItemIds: string[]): void {
    if (requiredItemIds.length > 0) {
      const queryParams = {
        id_list: requiredItemIds.join(','),
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        if (!environment.production) {
          console.log('Fetched required items:', response.result);
        }
      });
    }
  }

  private validateRequiredItems(): void {
    const discountRequirements = this.editDiscountForm.get('discountUseRequirementsRadios')?.value;
    const requiredItemIds = this.editDiscountForm.get('saleItemsRequiredSelections')?.value || [];

    if (discountRequirements === 'minimumItems' && requiredItemIds.length === 0) {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors({
        required: true,
      });
    } else {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors(null);
    }
  }

  // How do you want to apply the discount?
  getDiscountScope(): string {
    return this.discountSummaryService.getDiscountScope(this.editDiscountForm);
  }

  // How do you want to apply the discount card, Discount the sale items you choose option
  getSaleItemsPerOrderRadios(): string {
    return this.discountSummaryService.getSaleItemsPerOrderRadios(this.editDiscountForm);
  }

  // How do you want to apply the discount? card, Discount the sale items you choose option
  getSaleItemsText(): string {
    return this.discountSummaryService.getSaleItemsText(this.editDiscountForm);
  }

  //What are the requirements to use this discount? card
  getDiscountUseRequirements(): string {
    return this.discountSummaryService.getDiscountUseRequirements(this.editDiscountForm);
  }

  // What are the requirements to use this discount?
  getDiscountUsage(): string {
    return this.discountSummaryService.getDiscountUsage(this.editDiscountForm);
  }

  // What are the requirements to use this discount? card, eligible sale item selection
  getRequiredItemsText(): string {
    return this.discountSummaryService.getRequiredItemsText(this.editDiscountForm);
  }

  // What are the requirements to use this discount? card, Buy a minimum number (#) of sale items options
  getminimumItemsQtyField(): string {
    return this.discountSummaryService.getminimumItemsQtyField(this.editDiscountForm);
  }

  // When will this discount be available? card
  getDiscountDateRange(): string {
    return this.discountSummaryService.getDiscountDateRange(this.editDiscountForm);
  }

  // What is the value of the discount? card
  getFormattedDiscountValue(): string {
    return this.discountSummaryService.getFormattedDiscountValue(this.editDiscountForm);
  }

  getDiscountStyle(): string {
    const style = this.discountStyle;
    switch (style) {
      case 'Automatic':
        return 'automatic';
      case 'Individual':
        return `individual`;
      case 'Code':
        return `reusable`;
    }
  }

  getMaxUsage(): string {
    if (this.codeMaxUsage == null) {
      return 'This discount can be used an unlimited number of times';
    } else {
      return ` This discount can only be used ${this.codeMaxUsage} times `;
    }
  }

  getCodeUsage(): string {
    return ` ${this.initialCodeCount} individual codes have been generated for this discount. ${this.usedCodeCount} individual codes have been redeemed for this discount. `;
  }

  getDiscountScopeValue(): string {
    const value = this.editDiscountForm.get('applyDiscountTypeRadios')?.value;
    switch (value) {
      case 'subtotal':
        return 'Order';
      case 'saleItems':
        return 'Item';
      default:
        return '';
    }
  }

  getRequirementType(): string {
    const value = this.editDiscountForm.get('discountUseRequirementsRadios')?.value;
    switch (value) {
      case 'minimumItems':
        return 'Quantity';
      case 'minimumSpend':
        return 'PurchasePrice';
      default:
        return 'None';
    }
  }

  requiresItems(): boolean {
    return ['Quantity'].includes(this.getRequirementType());
  }

  discountsItems(): boolean {
    return ['Item'].includes(this.getDiscountScopeValue());
  }

  /*
   * Creates an object from form values to API compatible values
   * */
  mapForm() {
    return {
      id: this.discountID,
      name: this.editDiscountForm.get('discountName')?.value.trim(),
      discount_value: this.editDiscountForm.get('discountValueAmount')?.value,
      discount_type: this.editDiscountForm.get('discountValueType')?.value,
      discount_scope: this.getDiscountScopeValue(),
      discounted_item_variation_ids: this.discountsItems()
        ? this.editDiscountForm.get('saleItemsPerOrderSelections')?.value
        : [],
      max_eligible_items: this.getMaxEligibleItems(),
      requirement_type: this.getRequirementType(),
      minimum_sale_total: this.editDiscountForm.get('minimumSpendAmountField')?.value,
      required_items_quantity: this.editDiscountForm.get('minimumItemsQtyField')?.value,
      required_item_variation_ids: this.requiresItems()
        ? this.editDiscountForm.get('saleItemsRequiredSelections')?.value
        : [],
      maximum_discount_value: this.editDiscountForm.get('discountValueMaxOff')?.value,
      starts_at: this.discountFormService.formatDateForSubmission(this.editDiscountForm.get('fromDate')?.value),
      expires_at: this.discountFormService.formatDateForSubmission(this.editDiscountForm.get('toDate')?.value),
    };
  }
  getMaxEligibleItems(): number | null {
    const option = this.editDiscountForm.get('saleItemsPerOrderRadios')?.value;
    const discountedItems = this.editDiscountForm.get('saleItemsPerOrderSelections')?.value;
    switch (option) {
      case 'oneItem':
        return 1;
      case 'allItems':
        return 0;

      case 'setAmount':
        return this.editDiscountForm.get('setAmountQtyField').value;
      default:
        return null;
    }
  }

  /*
   * Updates validations of form controls depending on values
   * */
  setupConditionalValidation() {
    // How do you want to apply the discount?
    const saleItemsPerOrderRadios = this.editDiscountForm.get('saleItemsPerOrderRadios');
    const saleItemsPerOrderSelections = this.editDiscountForm.get('saleItemsPerOrderSelections');
    const setAmountQtyField = this.editDiscountForm.get('setAmountQtyField');
    const applyDiscountTypeRadios = this.editDiscountForm.get('applyDiscountTypeRadios');

    applyDiscountTypeRadios.valueChanges.subscribe((value) => {
      if (value === 'saleItems') {
        saleItemsPerOrderRadios.setValidators([Validators.required]);
        saleItemsPerOrderSelections.setValidators([Validators.required]);
      } else {
        saleItemsPerOrderRadios.clearValidators();
        saleItemsPerOrderSelections.clearValidators();
      }
      saleItemsPerOrderRadios.updateValueAndValidity();
      saleItemsPerOrderSelections.updateValueAndValidity();
    });

    saleItemsPerOrderRadios.valueChanges.subscribe((value) => {
      if (value === 'setAmount') {
        setAmountQtyField.setValidators([Validators.required, Validators.pattern(/^0*[1-9]\d*$/), Validators.min(1)]);
      } else {
        setAmountQtyField.clearValidators();
      }
      setAmountQtyField.updateValueAndValidity();
    });

    const discountUseRequirementsRadios = this.editDiscountForm.get('discountUseRequirementsRadios');
    const minimumSpendAmountField = this.editDiscountForm.get('minimumSpendAmountField');
    const saleItemsRequiredSelections = this.editDiscountForm.get('saleItemsRequiredSelections');
    const minimumItemsQtyField = this.editDiscountForm.get('minimumItemsQtyField');

    discountUseRequirementsRadios.valueChanges.subscribe((value) => {
      if (value === 'minimumSpend') {
        minimumSpendAmountField.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.pattern(/^\d+(?:\.\d{0,2})?$/),
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        ]);
        saleItemsRequiredSelections.clearValidators();
        minimumItemsQtyField.clearValidators();
      } else if (value === 'minimumItems') {
        saleItemsRequiredSelections.setValidators([Validators.required]);
        minimumItemsQtyField.setValidators([
          Validators.required,
          Validators.pattern(/^0*[1-9]\d*$/),
          Validators.min(1),
        ]);
        minimumSpendAmountField.clearValidators();
      } else {
        minimumSpendAmountField.clearValidators();
        saleItemsRequiredSelections.clearValidators();
        minimumItemsQtyField.clearValidators();
      }
      minimumItemsQtyField.updateValueAndValidity();
      saleItemsRequiredSelections.updateValueAndValidity();
      minimumSpendAmountField.updateValueAndValidity();
    });
  }

  subscribeToFormChanges(): void {
    this.editDiscountForm.get('fromDate').valueChanges.subscribe(this.updateExcludeDates.bind(this));
    this.editDiscountForm.get('toDate').valueChanges.subscribe(this.updateExcludeDates.bind(this));

    this.editDiscountForm
      .get('saleItemsPerOrderSelections')
      .valueChanges.pipe(debounceTime(500), take(1))
      .subscribe((selectedItems: number[]) => this.updateDiscountedItemsTable(selectedItems));

    this.editDiscountForm
      .get('saleItemsRequiredSelections')
      .valueChanges.pipe(debounceTime(500), take(1))
      .subscribe((requiredItems: number[]) => this.updateRequiredItemsTable(requiredItems));
  }

  formatDiscountValueAmount(value: number | string): string {
    if (value == null) {
      return '';
    }
    const num = Number(value);
    return num % 1 === 0 ? num.toString() : num.toFixed(2);
  }
}
